import React from 'react'
import { graphql, Link, navigate } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
const axios = require('axios')
import header from '../../images/CleanAir-Desktop-Header-1920x340-Curve-DataHub-2x.png'
import headerMobile from '../../images/CleanAir-Mobile-Header-1080x550-Curve-DataHub-2x.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import LinkBack from '../../components/link-back'
import GoogleMapReact from 'google-map-react'
const moment = require('moment')

class ForecastsIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sites: null,
      sitesIndex: null,
      indexed: false,
      dates: [],
      days: [],
      selectedDate: null,
      map: null,
      pollutant: 'Total',
      forecast: [],
    }
  }

  componentDidMount() {
    if (!this.state.selectedDate) {
      this.setState({
        dates: [
          moment().format('YYYYMMDD'),
          moment()
            .add(1, 'days')
            .format('YYYYMMDD'),
          moment()
            .add(2, 'days')
            .format('YYYYMMDD'),
        ],
      })
      this.setState({
        days: [
          moment().format('dddd'),
          moment()
            .add(1, 'days')
            .format('dddd'),
          moment()
            .add(2, 'days')
            .format('dddd'),
        ],
      })
      this.setState({ selectedDate: moment().format('YYYYMMDD') })
    }

    axios('https://cleanairgm.com/.netlify/functions/getForecast').then(
      response => {
        this.setState({ forecast: response.data })
      }
    )
  }

  render() {
    if (this.state.selectedDate) {
      const WMSGetTileUrl = (tile, zoom, map, maps) => {
        var projection = map.getProjection()
        var zpow = Math.pow(2, zoom)
        var ul = new maps.Point(
          (tile.x * 256.0) / zpow,
          ((tile.y + 1) * 256.0) / zpow
        )
        var lr = new maps.Point(
          ((tile.x + 1) * 256.0) / zpow,
          (tile.y * 256.0) / zpow
        )
        var ulw = projection.fromPointToLatLng(ul)
        var lrw = projection.fromPointToLatLng(lr)
        var bbox =
          ulw.lng() + ',' + ulw.lat() + ',' + lrw.lng() + ',' + lrw.lat()

        let baseURL =
          'https://www.airtext.info/cgi-bin/mapserv?MAP=../../MapImages/manchester2.map'
        var datetime = this.state.selectedDate
        var poll = this.state.pollutant
        var url =
          baseURL +
          '&date=' +
          datetime +
          '&version=1.1.1&SERVICE=WMS&request=GetMap&Layers=Manchester&pollutant=' +
          poll +
          '&Styles=default&SRS=EPSG:4326&BBOX=' +
          bbox +
          '&width=256&height=256&format=image/png&TRANSPARENT=TRUE'
        return url
      }

      const siteTitle = get(this, 'props.data.site.siteMetadata.title')

      const alerts = get(this, 'props.data.contentfulDataHub.alerts')

      const options = {
        fillColor: 'black',
        fillOpacity: 0.1,
        strokeColor: 'red',
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
      }

      const centerOverlayView = (width, height) => ({
        x: -(width / 2),
        y: -(height / 2),
      })

      const getClass = type => {
        if (type === 'Urban traffic') {
          return 'ut'
        }

        if (type === 'Urban background') {
          return 'ub'
        }

        if (type === 'Suburban background') {
          return 'sb'
        }

        if (type === 'Rural background') {
          return 'rb'
        }
      }

      const getPixelPositionOffset = (width, height) => ({
        x: -(width / 2),
        y: -(height / 2),
      })

      const handleApiLoaded = (map, maps) => {
        let wmsOptions = {
          alt: 'MapServer Layer',
          getTileUrl: (tile, zoom) => WMSGetTileUrl(tile, zoom, map, maps),
          isPng: false,
          maxZoom: 17,
          minZoom: 9,
          opacity: 0.7,
          name: 'MapServer Layer',
          tileSize: new maps.Size(256, 256),
        }

        let wmsMapType = new maps.ImageMapType(wmsOptions)
        map.overlayMapTypes.insertAt(0, wmsMapType)
        this.setState({ map })
      }

      return (
        <Layout location={this.props.location}>
          <div>
            <Helmet title={'Forecast and alerts | ' + siteTitle} />
            <div className="page-banner page-banner--disclaimer">
              <img className="d-none d-lg-block" src={header} alt="" />
              <img className="d-block d-lg-none" src={headerMobile} alt="" />
              <h1>Forecast and alerts</h1>
            </div>
          </div>

          <Container>
            <LinkBack to={'/data-hub'} title={'back to Data hub'} />

            <p>
              The map shows a forecast of expected air quality over the next
              three days, for a number of different air pollutants, so that you
              can plan ahead.
            </p>
            <p>
              The map displays the{' '}
              <a href="/daily-air-quality-index">
                Daily Air Quality Index (DAQI)
              </a>
                which is numbered 1-10 and divided into four bands, low (1) to
              very high (10), to indicate immediate short-term air
              pollution levels in a simple way, similar to the sun index or
              pollen index.​ While these are not linked to legal limits it
              can help to show whether you are likely to be at risk from air
              pollution on that particular day. It also recommends what you can
              do and gives health advice to consider over the next few hours or
              days.
            </p>

            <p>
              The Daily Air Quality Index doesn’t take into account the
              cumulative effect of long-term exposure to air pollution over
              months and years which may contribute to chronic health conditions
              – similar to smoking.{' '}
            </p>

            <p>
              Air pollution is above legal annual average limits in some areas
              of our region. As a result, government has instructed Greater
              Manchester to produce and implement a Clean Air Plan to address
              the harmful effects of long-term exposure to NO<sub>2</sub>{' '}
              pollution.{' '}
            </p>

            <p>
              There is separate long-term monitoring of annual average levels of
              NO<sub>2</sub> air pollution for the purpose of the Greater
              Manchester Clean Air Plan.
            </p>
            <Row className="mt-5 mb-4">
              <Col xs={12} lg={6}>
                <FormGroup>
                  <Label for="day">Choose forecast day</Label>
                  <Input
                    type="select"
                    name="day"
                    id="day"
                    onChange={e => {
                      this.setState({ selectedDate: e.target.value }, () => {
                        this.state.map.setZoom(this.state.map.getZoom())
                      })
                    }}
                    value={this.state.selectedDate}
                  >
                    <option value={this.state.dates[0]}>
                      {this.state.days[0]}
                    </option>
                    <option value={this.state.dates[1]}>
                      {this.state.days[1]}
                    </option>
                    <option value={this.state.dates[2]}>
                      {this.state.days[2]}
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12} lg={6}>
                <p className="mb-2">Choose pollutant</p>
                <Button
                  onClick={() => {
                    this.setState({ pollutant: 'Total' }, () => {
                      this.state.map.setZoom(this.state.map.getZoom())
                    })
                  }}
                  className={`${
                    this.state.pollutant === 'Total' ? 'active' : ''
                  } btn-pollutant mr-3`}
                >
                  Overall
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ pollutant: 'PM10' }, () => {
                      this.state.map.setZoom(this.state.map.getZoom())
                    })
                  }}
                  className={`${
                    this.state.pollutant === 'PM10' ? 'active' : ''
                  } btn-pollutant mr-3`}
                >
                  PM<sub>10</sub>
                </Button>

                <Button
                  onClick={() => {
                    this.setState({ pollutant: 'NO2' }, () => {
                      this.state.map.setZoom(this.state.map.getZoom())
                    })
                  }}
                  className={`${
                    this.state.pollutant === 'NO<sub>2</sub>' ? 'active' : ''
                  } btn-pollutant mr-3`}
                >
                  NO<sub>2</sub>
                </Button>

                <Button
                  onClick={() => {
                    this.setState({ pollutant: 'O3' }, () => {
                      this.state.map.setZoom(this.state.map.getZoom())
                    })
                  }}
                  className={`${
                    this.state.pollutant === 'O3' ? 'active' : ''
                  } btn-pollutant mr-3`}
                >
                  O<sub>3</sub>
                </Button>

                <Button
                  onClick={() => {
                    this.setState({ pollutant: 'PM25' }, () => {
                      this.state.map.setZoom(this.state.map.getZoom())
                    })
                  }}
                  className={`${
                    this.state.pollutant === 'PM25' ? 'active' : ''
                  } btn-pollutant`}
                >
                  PM<sub>25</sub>
                </Button>
              </Col>
            </Row>
            <div className="mb-5">
              <div style={{ height: '500px', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyA1yf9mWk13ntuoG5Lia0P-i3UH_igYi-A',
                  }}
                  defaultCenter={{
                    lat: 53.5151979,
                    lng: -2.35003194,
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                  defaultZoom={10}
                ></GoogleMapReact>
              </div>
            </div>

            <div className="quality-index">
              <h2>Daily air quality index</h2>
              <Row>
                <Col sm={12} lg={3}>
                  <h3>Low</h3>
                  <p>
                    <span className={`air-index c1 mr-4`}>1</span>
                    <span className={`air-index c2 mr-4`}>2</span>
                    <span className={`air-index c3`}>3</span>
                  </p>
                </Col>
                <Col sm={12} lg={3}>
                  <h3>Moderate</h3>
                  <p>
                    <span className={`air-index c4 mr-4`}>4</span>
                    <span className={`air-index c5 mr-4`}>5</span>
                    <span className={`air-index c6`}>6</span>
                  </p>
                </Col>
                <Col sm={12} lg={3}>
                  <h3>High</h3>
                  <p>
                    <span className={`air-index c7 mr-4`}>7</span>
                    <span className={`air-index c8 mr-4`}>8</span>
                    <span className={`air-index c9`}>9</span>
                  </p>
                </Col>
                <Col sm={12} lg={3}>
                  <h3>Very High</h3>
                  <p>
                    <span className={`air-index c10 mr-4`}>10</span>
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="alerts">
            <Container className="mt-5 mb-5">
              <Row>
                <Col sm={12} lg={6}>
                  <h2>{alerts.title}</h2>
                  <div
                    className="alerts__text"
                    dangerouslySetInnerHTML={{
                      __html: alerts.text.childMarkdownRemark.html,
                    }}
                  ></div>
                  <p>
                    <Link
                      className="btn btn-call"
                      to={'/data-hub/forecast-and-alerts/text-sign-up'}
                    >
                      Text
                    </Link>
                    <Link
                      className="btn btn-call"
                      to={'/data-hub/forecast-and-alerts/email-sign-up'}
                    >
                      Email
                    </Link>
                    <Link
                      className="btn btn-call"
                      to={'/data-hub/forecast-and-alerts/recorded-call-sign-up'}
                    >
                      Recorded call
                    </Link>
                  </p>
                </Col>
                <Col sm={12} lg={6}>
                  <Img className="alerts__image" fluid={alerts.image.fluid} />
                </Col>
              </Row>
            </Container>
            <Container className="mt-5 mb-5">
              <h2>Live pollution alerts</h2>
              {this.state.forecast.map((f, i) => (
                <Row key={i} className="mb-4 mt-4 pollution-alert">
                  <Col xs={12}>
                    <h3>{f.ZoneName}</h3>
                  </Col>
                  <Col xs={12} lg={4}>
                    <p>
                      {moment(f.Forecasts[0].Date).format('dddd, MMMM Do')} :{' '}
                      <span className={f.Forecasts[0].AlertLevel.toLowerCase()}>
                        {f.Forecasts[0].AlertLevel}
                      </span>
                    </p>
                  </Col>
                  <Col xs={12} lg={4}>
                    <p>
                      {moment(f.Forecasts[1].Date).format('dddd, MMMM Do')} :{' '}
                      <span className={f.Forecasts[1].AlertLevel.toLowerCase()}>
                        {f.Forecasts[1].AlertLevel}
                      </span>
                    </p>
                  </Col>
                  <Col xs={12} lg={4}>
                    <p>
                      {moment(f.Forecasts[2].Date).format('dddd, MMMM Do')} :{' '}
                      <span className={f.Forecasts[2].AlertLevel.toLowerCase()}>
                        {f.Forecasts[2].AlertLevel}
                      </span>
                    </p>
                  </Col>
                </Row>
              ))}
            </Container>
          </div>
        </Layout>
      )
    } else {
      return null
    }
  }
}

export default ForecastsIndex

export const pageQuery = graphql`
  query ForecastsQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulDataHub {
      alerts {
        text {
          childMarkdownRemark {
            html
          }
        }
        id
        title
        image {
          fluid(maxWidth: 670, maxHeight: 377) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
